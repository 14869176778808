<template>
  <div class="chart-fluid">
    <canvas id="planet-chart" class="chart-fluid"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  data() {
    return {
      grand_total: [],
      NumberDatas: "",
      ChartData: {
        type: "line",
        data: {
          labels: ["Mon", "Tues", "Wed", "Thurs", "Friday"],
          datasets: [
            {
              label: "Sales analysis",
              data: [3560, 4560, 456, 745, 955, 1255, 34],
              borderColor: "#36495d",
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById("planet-chart");
    new Chart(ctx, this.ChartData);
  },

  async created() {
    this.transactionAnalysis = this.$store.state["vendor"].analysis;
    this.graphData = this.$store.state["vendor"].analysis.monthly_sales;
    for (let i = 0; i < this.graphData.length; i++) {
      this.grand_total.push(this.graphData[i].grand_total);
    }
    this.NumberDatas = this.grand_total.map((i) => Number(i));
    this.ChartData = {
      type: "line",
      data: {
        labels: ["Mon", "Tues", "Wed", "Thurs", "Friday"],
        datasets: [
          {
            label: "Sales analysis",
            data: this.NumberDatas,
            borderColor: "#36495d",
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        lineTension: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                padding: 25,
              },
            },
          ],
        },
      },
    };
  },
};
</script>

<style>
.chart-fluid {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
</style>
