<template>
  <div>
    <div class="dash-title">
      <div class="title">Analytics Overview</div>
      <div>
        <select class="form-select" aria-label="Default select example">
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
    </div>

    <div class="container">
      <div class="row mt-4">
        <div class="col-md-4 col-12 mb-3">
          <div class="summary-box">
            <div class="header-items">
              <div class="total-order">Total Orders</div>
              <div class="logo"><img :src="orderLogo" alt="totalOrder" /></div>
            </div>
            <div class="order-amount">
              <div class="total-no">&#8358;{{ transactionAnalysis?.orders || 0 }}</div>
              <div><!--NGN 50,000--></div>
            </div>
            <div class="conpare-no">Compared to (231 Yesterday)</div>
          </div>
        </div>

        <div class="col-md-4 col-12 mb-3">
          <div class="summary-box">
            <div class="header-items">
              <div class="total-order">Unique Customers</div>
              <div class="logo1"><img :src="customers" alt="totalOrder" /></div>
            </div>
            <div class="order-amount">
              <div class="total-no">{{ transactionAnalysis?.users || 0 }}</div>
            </div>
            <div class="conpare-no">Compared to (231 Yesterday)</div>
          </div>
        </div>

        <div class="col-md-4 col-12 mb-3">
          <div class="summary-box">
            <div class="header-items">
              <div class="total-order">Net Sales</div>
              <div class="logo"><img :src="netSales" alt="totalOrder" /></div>
            </div>
            <div class="order-amount">
              <div class="total-no">&#8358;{{ transactionAnalysis?.revenue || 0 }}</div>
            </div>
            <div class="conpare-no">Compared to (231 Yesterday)</div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-8">
          <div class="title">Sales Analytics</div>
          <div class="graph mt-3">
            <PlanetChart />
          </div>

          <div class="delivery mt-3 py-3">
            <div class="d-title">Delivery Boys</div>
            <div class="delivery-images mt-3">
              <div><img :src="dBoy1" alt="" /></div>
              <div><img :src="dBoy2" alt="" /></div>
              <div><img :src="dBoy3" alt="" /></div>
              <div><img :src="dBoy4" alt="" /></div>
              <div><img :src="dBoy5" alt="" /></div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="title">Popular Items / Sales</div>
          <div class="sales-items mt-3">
            <div class="items-background">
              <div class="inner-flex">
                <div class="index">1</div>
                <div>
                  <div class="item-name">Beef &amp; Rice Combo</div>
                  <div class="order-no">
                    <div class="items-nos">201 Orders</div>
                    <div class="amount-earned">NGN 23,000 Earned</div>
                  </div>
                </div>
              </div>

              <div class="inner-flex mt-4">
                <div class="index">2</div>
                <div>
                  <div class="item-name">Beef &amp; Rice Combo</div>
                  <div class="order-no">
                    <div class="items-nos">201 Orders</div>
                    <div class="amount-earned">NGN 23,000 Earned</div>
                  </div>
                </div>
              </div>

              <div class="inner-flex mt-4">
                <div class="index">3</div>
                <div>
                  <div class="item-name">Beef &amp; Rice Combo</div>
                  <div class="order-no">
                    <div class="items-nos">201 Orders</div>
                    <div class="amount-earned">NGN 23,000 Earned</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PlanetChart from "../../../VendorPages/VendorComponents/DashboardPages/chart.vue";

export default {
  data() {
    return {
      orderLogo: require("../../../../assets/total-order.png"),
      customers: require("../../../../assets/customers.png"),
      netSales: require("../../../../assets/net_sales.png"),
      dBoy1: require("../../../../assets/boy1.png"),
      dBoy2: require("../../../../assets/boy2.png"),
      dBoy3: require("../../../../assets/boy3.png"),
      dBoy4: require("../../../../assets/boy4.png"),
      dBoy5: require("../../../../assets/boy5.png"),
      transactionAnalysis: "",
      graphData: "",
    };
  },

  components: {
    PlanetChart,
  },

  methods: {
    ...mapActions({
      getVendors: "vendor/getVendor",
      vendorOrders: "vendor/vendorOrders",
      allOrders: "vendor/getOrders",
    }),
  },

  async created() {
    let user_id = this.$store.state["auth"].user?.id;
    this.getVendors(user_id);
    this.vendorOrders();
    this.allOrders();
    this.transactionAnalysis = this.$store.state["vendor"].analysis;
  },
};
</script>

<style scoped>
.dash-title {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
  margin-left: 5%;
}

.d-title {
  color: #04030f;
  margin-left: 20px;
  font-weight: 600;
  padding: 5px;
}
.delivery-images {
  display: flex;
  justify-content: space-around;
}

.inner-flex {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 8px;
}

.items-nos {
  font-size: 10px;
  margin-right: 15px;
}

.amount-earned {
  font-size: 10px;
  color: rgba(122, 122, 122, 1);
}

.index {
  background-color: rgba(238, 223, 212, 1);
  padding: 4px 14px;
  text-align: center;
  border-radius: 3px;
  /* font-size:10px; */
}

.item-name {
  font-size: 14px;
  margin-left: 15px;
  color: rgba(67, 66, 76, 1);
  font-weight: 600;
}

.order-no {
  font-size: 10px;
  margin-left: 15px;
}
.items-background {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.graph {
  background: #ffffff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 19rem;
  padding: 20px;
}

.order-no {
  display: flex;
}

.sales-items {
  background: #ffffff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 19rem;
  padding: 18px;
}

.logo {
  background-color: rgba(207, 205, 222, 1);
  padding: 0px 5px;
  border-radius: 3px;
}

.logo1 {
  background-color: rgba(236, 215, 201, 1);
  padding: 0px 5px;
  border-radius: 3px;
}

.total-no {
  margin-right: 20px;
  font-size: 20px;
  font-weight: 600;
  color: rgba(4, 3, 15, 1);
}

.total-order {
  color: rgba(133, 77, 39, 1);
  font-weight: 600;
}

.main-container {
  margin-left: 5%;
}

.conpare-no {
  font-size: 12px;
  color: rgba(122, 122, 122, 1);
}

.order-amount {
  display: flex;
  margin-top: 15px;
}

.title {
  color: rgba(4, 3, 15, 1);
  font-weight: 600;
  font-size: 15px 20px;
}

.form-select {
  outline: none;
  border: none;
  box-shadow: none;
  color: black;
  padding: 2px 30px;
  /* background: #FFFFFF; */
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.summary-box {
  background: #ffffff;
  border: 0.5px solid #43424c;
  box-sizing: border-box;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.header-items {
  display: flex;
  justify-content: space-between;
}

.s-box {
  margin-right: 0px !important;
  width: 35%;
}

.delivery {
  background: #ffffff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
</style>
